import { map } from 'lodash'
import React, { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useGetLoggedInUser } from '@cotiss/user'
import { TenderInvitationDrawer } from '@cotiss/tender-invitation'
import {
  Button,
  Drawer,
  Icon,
  OnTransitionParam,
  Text,
  Tooltip_DEPRECATED,
  TransitionContainer,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'
import {
  OrganisationCountryCode,
  OrganisationCountryCodeSubdivision,
  OrganisationEntityType,
  OrganisationSearchResponse,
  PreferredSupplierSearchMethod,
  useListOrganisationSearch,
} from '@cotiss/organisation'
import {
  PreferredSupplierAddDetailsStep,
  PreferredSupplierAddOverviewStep,
  PreferredSupplierContactModel,
  PreferredSupplierInvitationLinkModal,
  PreferredSupplierListOrganisationsStep,
  PreferredSupplierOrganisationPreviewStep,
  PreferredSupplierPopulatedModel,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier'

export type PreferredSupplierAddFormData = {
  entityType?: OrganisationEntityType
  organisationName: string
  countryCode?: OrganisationCountryCode
  countryCodeSubdivision?: OrganisationCountryCodeSubdivision
  businessNumber: string
  contacts: PreferredSupplierContactModel[]
  searchMethod?: PreferredSupplierSearchMethod
}

type Props =
  | {
      tenderId?: never
      isEditable?: boolean
      isTenderInvitation?: never
      onClose?: () => void | Promise<void>
    }
  | {
      tenderId: string
      isEditable?: boolean
      isTenderInvitation: true
      onClose?: never
    }

export const PreferredSupplierAddDrawer = memo(({ tenderId, isEditable, isTenderInvitation, onClose }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { openModal, openDrawer, closeDrawer } = useCallout()
  const { user, isLoading: isLoadingUser } = useGetLoggedInUser()
  const { createPreferredSupplier } = useMutatePreferredSupplier()
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const [organisations, setOrganisations] = useState<OrganisationSearchResponse[]>([])
  const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationSearchResponse | undefined>(undefined)
  const [formData, setFormData] = useState<PreferredSupplierAddFormData>({
    organisationName: '',
    businessNumber: '',
    contacts: [],
  })
  const processedCountryCode = formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode
  const { isFetching: isLoadingOrganisations, refetch: refetchOrganisations } = useListOrganisationSearch({
    type: formData.searchMethod ?? 'name',
    name: formData.organisationName,
    countryCode: processedCountryCode,
    businessNumber: formData.businessNumber,
    isEnabled: false,
  })

  const handleTenderInvitation = async (preferredSupplier?: PreferredSupplierPopulatedModel) => {
    if (!tenderId || !preferredSupplier) {
      return
    }

    openDrawer(<TenderInvitationDrawer tenderId={tenderId} preferredSupplierId={preferredSupplier._id} isEditable={isEditable} isNewContact />)
  }

  const handleSubmit = async () => {
    try {
      if (step === 1) {
        setIsSaving(true)
        const { data: organisationSearchResults } = await refetchOrganisations()
        setIsSaving(false)
        setOrganisations(organisationSearchResults ?? [])

        // Only show list organisations step if there are organisations to show otherwise, skip to add details step
        if (organisationSearchResults && organisationSearchResults.length > 0) {
          onTransition({ step: 2 }) // List organisations step
          return
        }

        onTransition({ step: 4 }) // Add details step
        return
      } else if (step === 2) {
        onTransition({ step: 3 }) // Preview step
        return
      }

      setIsSaving(true)
      let preferredSupplier

      if (!selectedOrganisation) {
        preferredSupplier = await createPreferredSupplier({
          name: formData.organisationName,
          businessNumber: formData.businessNumber,
          countryCode: processedCountryCode,
          entityType: formData.entityType,
          contacts: map(formData.contacts, (contact) => ({
            ...contact,
            sendInvitationEmail: isTenderInvitation ? false : contact.sendInvitationEmail,
          })),
        })
      } else if (selectedOrganisation?.internalId) {
        preferredSupplier = await createPreferredSupplier({
          supplierOrganisation: selectedOrganisation.internalId,
        })
      } else if (selectedOrganisation) {
        preferredSupplier = await createPreferredSupplier({
          name: selectedOrganisation.name ?? '',
          businessNumber: selectedOrganisation.businessNumber,
          countryCode: processedCountryCode,
          entityType: formData.entityType,
          kyckrId: selectedOrganisation.externalId,
          contacts: map(formData.contacts, (contact) => ({
            ...contact,
            sendInvitationEmail: isTenderInvitation ? false : contact.sendInvitationEmail,
          })),
        })
      }

      if (isTenderInvitation) {
        await handleTenderInvitation(preferredSupplier)
      } else {
        openToast('Contact added')

        if (onClose) {
          await onClose()
        }

        closeDrawer()
      }
    } catch (error: any) {
      setIsSaving(false)
      throw error
    }
  }

  const onSelectOrganisation = (organisation: OrganisationSearchResponse) => {
    setSelectedOrganisation(organisation)
    onTransition({ step: 3 })
    return
  }

  const handleBreadcrumbTransition = (transitionParams: OnTransitionParam) => {
    setSelectedOrganisation(undefined)
    onTransition(transitionParams)
  }

  const renderHeader = () => (
    <div className="flex items-center justify-between flex-1">
      <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
        {isTenderInvitation ? 'Invite' : 'Add'} contact
      </Text>
      {!isTenderInvitation && (
        <Button
          className="mr-2"
          state="text"
          variant="secondary"
          onClick={() =>
            user?.account.organisation?._id && openModal(<PreferredSupplierInvitationLinkModal organisationId={user?.account.organisation?._id} />)
          }
          isDisabled={isLoadingUser}
        >
          <Icon icon="link-03" /> Copy invite link
        </Button>
      )}
    </div>
  )

  const renderFooter = () => (
    <AnimatePresence mode="wait" initial={false}>
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Continue <Icon icon="arrow-right" className="text-white ml-1" />
          </Button>
        )}
        {step === 4 && (
          <Tooltip_DEPRECATED
            position="centre"
            tooltip="Please add at least one contact"
            isEnabled={Boolean(isTenderInvitation && !formData.contacts.length)}
          >
            <Button
              type="submit"
              variant="secondary"
              isDisabled={isTransitioning || Boolean(isTenderInvitation && !formData.contacts.length)}
              isLoading={isSaving}
            >
              Add to list
            </Button>
          </Tooltip_DEPRECATED>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionContainer className="flex-1" key={step} transition={transition}>
          {step === 1 && (
            <PreferredSupplierAddOverviewStep
              formData={formData}
              isDisabled={isTransitioning}
              isSaving={isSaving || isLoadingOrganisations}
              setFormData={setFormData}
            />
          )}
          {step === 2 && (
            <PreferredSupplierListOrganisationsStep
              breadcrumbs={[
                { label: 'Add contact', onClick: () => handleBreadcrumbTransition({ step: 1, transition: 'left' }) },
                { label: 'Search results' },
              ]}
              formData={formData}
              isDisabled={isTransitioning}
              isLoading={isLoadingOrganisations}
              onBack={() => handleBreadcrumbTransition({ step: 1, transition: 'left' })}
              onCreateNewContact={() => onTransition({ step: 4 })}
              onSelectOrganisation={onSelectOrganisation}
              organisations={organisations}
            />
          )}
          {step === 3 && selectedOrganisation && (
            <PreferredSupplierOrganisationPreviewStep
              breadcrumbs={[
                { label: 'Add contact', onClick: () => handleBreadcrumbTransition({ step: 1, transition: 'left' }) },
                { label: 'Search results', onClick: () => handleBreadcrumbTransition({ step: 2, transition: 'left' }) },
                { label: 'Preview' },
              ]}
              formData={formData}
              isDisabled={isTransitioning}
              isSaving={isSaving}
              isTenderInvitation={isTenderInvitation}
              onBack={() => handleBreadcrumbTransition({ step: 2, transition: 'left' })}
              organisation={selectedOrganisation}
              setFormData={setFormData}
            />
          )}
          {step === 4 && (
            <PreferredSupplierAddDetailsStep
              breadcrumbs={[
                { label: 'Add contact', onClick: () => handleBreadcrumbTransition({ step: 1, transition: 'left' }) },
                { label: 'Search results' },
              ]}
              formData={formData}
              isDisabled={isTransitioning}
              isSaving={isSaving}
              isTenderInvitation={isTenderInvitation}
              onBack={() => handleBreadcrumbTransition({ step: 1, transition: 'left' })}
              setFormData={setFormData}
              showWarningBanner={!organisations.length}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
