import {
  Button,
  ConfirmModal,
  Icon,
  Page,
  PageContent,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  TableRowCta,
  Text,
  datetimeService,
  routerService,
  useCallout,
} from '@cotiss/common'
import { ProcurementViewHeader } from '@cotiss/procurement'
import { ProcurementResponseUserAddModal, useGetProcurementResponse } from '@cotiss/procurement-response'
import { useMutateProcurementResponseUser } from '@cotiss/procurement-response-user'
import { useGetLoggedInUser, userService } from '@cotiss/user'
import { map, some } from 'lodash'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export const ProcurementResponseViewProcurementResponseTeamPage = memo(() => {
  const { push, replace } = useHistory()
  const { openModal } = useCallout()
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const { procurementResponseId } = useParams<{ procurementResponseId: string }>()
  const { procurementResponse, isLoading: isLoadingProcurementResponse } = useGetProcurementResponse(procurementResponseId)
  const { deleteProcurementResponseUser } = useMutateProcurementResponseUser()
  const isLoading = isLoggedInUserLoading || isLoadingProcurementResponse

  const isUserInProcurementResponse = useMemo(() => {
    if (!procurementResponse || !loggedInUser) {
      return false
    }

    return some(procurementResponse.procurementResponseUsers, ({ user }) => loggedInUser._id === user._id)
  }, [procurementResponse, loggedInUser])

  useEffect(() => {
    if (!isLoading && !isUserInProcurementResponse) {
      replace(
        procurementResponse
          ? routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId: procurementResponse.procurement._id })
          : routerService.getHref('/procurement-response/list')
      )
    }
  }, [isLoading, isUserInProcurementResponse, procurementResponse])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Name',
        rows: map(procurementResponse?.procurementResponseUsers, ({ _id: procurementResponseUserId, user }) => ({
          content: () => (
            <Text className="truncate" font="jakarta">
              {userService.getFullName(user)}
            </Text>
          ),
          cta:
            loggedInUser?._id === user._id ? (
              <Icon icon="lock" variant="light" size={20} />
            ) : (
              <TableRowCta
                actions={[
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete team member"
                          description="Are you sure you want to remove this team member from this procurement response?"
                          onSubmit={() => deleteProcurementResponseUser(procurementResponseUserId)}
                        />
                      ),
                  },
                ]}
              />
            ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Email',
        rows: map(procurementResponse?.procurementResponseUsers, ({ user }) => ({
          content: () => <Text>{user.email}</Text>,
        })),
      },
      {
        heading: 'Date added',
        rows: map(procurementResponse?.procurementResponseUsers, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [loggedInUser, procurementResponse])

  return (
    <Page>
      <ProcurementViewHeader
        activeTab="procurement-team"
        procurementId={procurementResponse?.procurement._id}
        procurementResponseId={procurementResponse?._id}
      />
      <PageContent className="w-full">
        <TableHeader variant="white">
          <div className="flex justify-between items-center">
            <div>
              <Text className="font-semibold" font="jakarta" size="h7">
                Team
              </Text>
              <Text size="sm" variant="light">
                Users must be added to your organisation in your
                <Button
                  className="ml-1"
                  state="text"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
                  }
                >
                  Settings.
                </Button>
              </Text>
            </div>

            <Button
              size="sm"
              variant="secondary"
              onClick={
                procurementResponse
                  ? () => openModal(<ProcurementResponseUserAddModal procurementResponseId={procurementResponse?._id} />)
                  : undefined
              }
              isDisabled={isLoading}
            >
              <Icon icon="plus-01" />
              Add team member
            </Button>
          </div>
        </TableHeader>
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
      </PageContent>
    </Page>
  )
})
