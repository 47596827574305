import { map } from 'lodash'
import React, { memo } from 'react'
import { UserLineItem } from '@cotiss/user'
import { ApprovalModel, ApprovalStatusBadge } from '@cotiss/approval'
import { Button, Hr, Icon, ModalContent, Text, useCallout } from '@cotiss/common'

type Props = {
  rejectedApprovals: ApprovalModel[]
}

export const ApprovalFeedbackModal = memo(({ rejectedApprovals }: Props) => {
  const { closeModal } = useCallout()

  return (
    <div className="relative min-w-[450px] max-w-[450px]">
      <ModalContent isScrollable>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square">
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5" font="jakarta">
          Feedback for review
        </Text>

        <Text className="mt-2" variant="light" size="sm">
          Review approval feedback. Once ready, please re-request approval.
        </Text>

        {map(rejectedApprovals, (approval) => (
          <div key={approval._id} className="bg-primary-50 rounded-lg mt-4 p-2">
            <div className="flex items-center justify-between mb-2">
              <UserLineItem {...approval.assigned} />
              <ApprovalStatusBadge status={approval.status} />
            </div>
            <Hr className="my-2" />
            <Text className="break-words whitespace-pre-wrap">{approval.feedback}</Text>
          </div>
        ))}
      </ModalContent>
    </div>
  )
})
