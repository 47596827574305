import React, { FormEvent, memo, useState } from 'react'
import { ApprovalModel, useMutateApproval } from '@cotiss/approval'
import {
  Button,
  Form,
  Icon,
  ModalConfirmationButton,
  ModalContent,
  Text,
  TextArea,
  routerService,
  sentryService,
  useCallout,
  useToast,
} from '@cotiss/common'
import { useHistory } from 'react-router-dom'

type Props = {
  approval: ApprovalModel
}

export const ApprovalRejectConfirmModal = memo(({ approval }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateApproval } = useMutateApproval()
  const [isSaving, setIsSaving] = useState(false)
  const [feedback, setFeedback] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateApproval(approval._id, { status: 'rejected', feedback })
      push(routerService.getHref('/approval/view/:id', { id: approval._id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5" font="jakarta">
          {approval.approvalType === 'go-to-market' ? 'Reject go to market' : 'Reject recommendation report'}
        </Text>

        <Text className="mt-4">Please provide a reason for rejecting.</Text>
        <TextArea className="mt-1" value={feedback} rows={4} onChange={({ target }) => setFeedback(target.value)} isDisabled={isSaving} isRequired />
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Reject
      </ModalConfirmationButton>
    </Form>
  )
})
