import classNames from 'classnames'
import React, { memo } from 'react'
import { Button, Icon, Text, useCallout } from '@cotiss/common'

type Props = {
  className?: string
  heading: string
  supplementary?: string
  isDisabled?: boolean
  onClose?: () => void | Promise<unknown>
}

export const ModalHeader = memo(({ className, heading, supplementary, onClose, isDisabled }: Props) => {
  const { closeModal } = useCallout()
  const classes = classNames(className, 'flex items-center justify-between border-b border-gray-100 py-4 px-10 w-full')

  return (
    <div className={classes}>
      <div>
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          {heading}
        </Text>
        {supplementary && (
          <Text size="sm" variant="light">
            {supplementary}
          </Text>
        )}
      </div>
      <Button className="ml-2" onClick={onClose || closeModal} state="ghost" shape="square" size="sm" isDisabled={isDisabled}>
        <Icon icon="x-close" size={20} />
      </Button>
    </div>
  )
})
