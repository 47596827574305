import React, { FormEvent, memo, useState } from 'react'
import { TenderResponsePopulatedModel, useMutateTenderResponse } from '@cotiss/tender-response'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
}

export const TenderResponseSubmitConfirmModal = memo(({ tenderResponse }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTenderResponse } = useMutateTenderResponse()
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, { status: 'submitted' })
      closeModal()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5" font="jakarta">
          Confirm your submission
        </Text>

        <Text className="mt-4">Are you sure you would like finalise your tender submission?</Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
