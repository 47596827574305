import { find, findIndex } from 'lodash'
import React, { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import { SettingsTab, SettingsMembersTable, SettingsOrganisationTab } from '@cotiss/settings'
import { UserAddUpdateModal, useGetLoggedInUser, useListPendingUser, useListRequestedUser, useListUser, useUserAccess } from '@cotiss/user'
import {
  Button,
  Card,
  CardHeader,
  Icon,
  TabModel,
  Tabs,
  Text,
  Tooltip_DEPRECATED,
  TransitionContainer,
  routerService,
  useCallout,
  useTransition,
} from '@cotiss/common'

type SettingsAccountMembersTab = 'active' | 'pending' | 'requests'

export const SettingsOrganisationAccountMembersTab = memo(() => {
  const { openModal } = useCallout()
  const { permissions } = useUserAccess()
  const { users } = useListUser()
  const { pendingUsers } = useListPendingUser()
  const { requestedUsers } = useListRequestedUser()
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()

  // ------ TODO: remove tabs once filtering implemented ------
  const tabs: TabModel<SettingsAccountMembersTab>[] = [
    { id: 'active', label: `Active${users?.length ? ` (${users?.length})` : ''}` },
    { id: 'pending', label: `Pending${pendingUsers?.length ? ` (${pendingUsers?.length})` : ''}` },
    { id: 'requests', label: `Requests${requestedUsers?.length ? ` (${requestedUsers?.length})` : ''}` },
  ]
  const { push, replace } = useHistory()
  const { tab, nestedTab, subNestedTab } = useParams<{
    tab: SettingsTab
    nestedTab: SettingsOrganisationTab
    subNestedTab?: SettingsAccountMembersTab
  }>()
  const { step, transition, onTransition } = useTransition({
    initialStep: findIndex(tabs, ({ id }) => id === subNestedTab) + 1,
  })

  useEffect(() => {
    if (!subNestedTab || !find(tabs, (tab) => tab.id === subNestedTab)) {
      replace(
        routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', {
          tab: tab,
          nestedTab: nestedTab,
          subNestedTab: tabs[0].id,
        })
      )
    }

    const newStep = findIndex(tabs, ({ id }) => id === subNestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [subNestedTab])

  // ------------------------------------------------------------

  return (
    <Card>
      <CardHeader>
        <Text className="font-medium" variant="heading" size="h5" font="jakarta">
          Account members
        </Text>
        {!isLoggedInUserLoading && permissions.isAdmin && !loggedInUser?.ssoEnabled && (
          <Button onClick={() => openModal(<UserAddUpdateModal />)} variant="secondary" size="sm">
            <Icon className="mr-1" icon="plus-01" />
            Add new user
          </Button>
        )}
        {(isLoggedInUserLoading || (permissions.isAdmin && loggedInUser?.ssoEnabled)) && (
          <Tooltip_DEPRECATED tooltip="Adding and managing users must be done through your SSO service provider">
            <Button isDisabled state="translucent" variant="secondary" size="sm">
              <Icon className="mr-1" icon="plus-01" />
              Add new user
            </Button>
          </Tooltip_DEPRECATED>
        )}
      </CardHeader>
      <Tabs<SettingsAccountMembersTab>
        className="border-b border-gray-300 w-full mb-8"
        tab={subNestedTab}
        tabs={tabs}
        onChange={({ id }) => push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab, subNestedTab: id }))}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <SettingsMembersTable statusFilter="ACTIVE" />}
          {step === 2 && <SettingsMembersTable statusFilter="PENDING" />}
          {step === 3 && <SettingsMembersTable statusFilter="REQUESTED" />}
        </TransitionContainer>
      </AnimatePresence>
    </Card>
  )
})
