import React, { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button, Drawer, sentryService, Text, TransitionContainer, useAsyncEffect, useCallout, useToast, useTransition } from '@cotiss/common'
import {
  PerformanceScorecardEvaluatorUpdateMetricsStep,
  PerformanceScorecardEvaluatorViewMetricsStep,
  usePerformanceScorecardMetricUser,
  usePerformanceScorecardUser,
} from '@cotiss/performance'

type Props = {
  performanceScorecardId: string
  performanceScorecardUserId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardEvaluatorViewUpdateDrawer = memo(({ performanceScorecardId, performanceScorecardUserId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const { queryPerformanceScorecardUserView } = usePerformanceScorecardUser()
  const [performanceScorecardMetricIds, setPerformanceScorecardMetricIds] = useState<string[]>([])
  const { queryPerformanceScorecardMetricUserList, mutateCreatePerformanceScorecardMetricUsers } = usePerformanceScorecardMetricUser()

  useAsyncEffect(async () => {
    try {
      await Promise.all([
        queryPerformanceScorecardMetricUserList({ filter: { performanceScorecardId, performanceScorecardUserId } }),
        queryPerformanceScorecardUserView({ performanceScorecardUserId }),
      ])
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [performanceScorecardUserId, performanceScorecardId])

  const handleSubmit = async () => {
    if (step === 1) {
      return onTransition({ step: 2 })
    }

    setIsSaving(true)

    try {
      await mutateCreatePerformanceScorecardMetricUsers({
        performanceScorecardId,
        performanceScorecardUserIds: [performanceScorecardUserId],
        performanceScorecardMetricIds,
      })
      await Promise.all([queryPerformanceScorecardMetricUserList({ filter: { performanceScorecardId, performanceScorecardUserId } }), onSubmit()])
      setPerformanceScorecardMetricIds([])
      onTransition({ step: 1, transition: 'left' })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const handleBack = () => {
    setPerformanceScorecardMetricIds([])
    onTransition({ step: 1, transition: 'left' })
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      View evaluator
    </Text>
  )

  const renderFooter = () => {
    if (step === 2) {
      return (
        <div className="flex items-center">
          <Button type="submit" variant="secondary" isDisabled={!performanceScorecardMetricIds.length} isLoading={isSaving}>
            Save
          </Button>
          <Text className="ml-2">{performanceScorecardMetricIds.length} selected</Text>
        </div>
      )
    }
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <PerformanceScorecardEvaluatorViewMetricsStep
              performanceScorecardId={performanceScorecardId}
              performanceScorecardUserId={performanceScorecardUserId}
            />
          )}
          {step === 2 && (
            <PerformanceScorecardEvaluatorUpdateMetricsStep
              performanceScorecardId={performanceScorecardId}
              performanceScorecardUserId={performanceScorecardUserId}
              performanceScorecardMetricIds={performanceScorecardMetricIds}
              setPerformanceScorecardMetricIds={setPerformanceScorecardMetricIds}
              onBack={handleBack}
              isDisabled={isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
