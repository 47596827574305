import React, { memo, useEffect } from 'react'
import { useUserAccess } from '@cotiss/user'
import { ContractList, ContractCreateDrawer, CONTRACT_LIST_PRIMARY_TAB_FILTERS } from '@cotiss/contract'
import {
  Button,
  FourOhThreePage,
  Header,
  Icon,
  Page,
  PageContent,
  TabLabelWithCount,
  TabModel,
  Text,
  VerticalDivider,
  filterService,
  useAnalytics,
  useCallout,
  useFeature,
} from '@cotiss/common'
import {
  ContractSearchFiltersContextProvider,
  PrimaryTabKeys,
  useContractSearchFiltersContext,
} from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { ContractListSearchInput } from '@cotiss/contract/components/contract-list-search-input.component'
import { AdvancedFiltersPillList } from '@cotiss/contract/pages/advanced-filters-pill-list.component'
import {
  AdvancedFiltersDropdown,
  AdvancedFiltersDropdownContent,
  AdvancedFiltersDropdownTrigger,
  AdvancedFiltersProvider,
  useAdvancedFiltersContext,
} from '@cotiss/common/modals'

import { ContractListTabs } from '@cotiss/contract/pages/contract-list-tabs.component'
import { ContractListStatusCards } from '@cotiss/contract/components/contract-list-status-cards.component'
import { useContractListFilterTotals } from '@cotiss/contract/hooks'

const DEPRECATED_CONTRACT_LIST_TABS: TabModel<PrimaryTabKeys>[] = [
  { id: 'active', label: 'All contracts' },
  { id: 'archived', label: 'Archive' },
]

const ContractListPageInternal = memo(() => {
  const { openDrawer } = useCallout()
  const { permissions } = useUserAccess()
  const { track } = useAnalytics()
  const { validAdvancedFilters, isInitialized } = useAdvancedFiltersContext()
  const { queryState } = useContractSearchFiltersContext()
  const enableContractListStatusCards = useFeature('enable-contract-list-status-cards')

  useEffect(() => {
    track('contract_list_view')
  }, [])

  const { totals } = useContractListFilterTotals({
    totalFilters: CONTRACT_LIST_PRIMARY_TAB_FILTERS,
  })

  const CONTRACT_LIST_TABS: TabModel<PrimaryTabKeys>[] = [
    { id: 'active', label: <TabLabelWithCount count={totals?.active?.total} label="All contracts" /> },
    { id: 'mine', label: <TabLabelWithCount count={totals?.mine?.total} label="My contracts" /> },
    { id: 'archived', label: <TabLabelWithCount count={totals?.archived?.total} label="Archive" /> },
  ]

  const tabs = enableContractListStatusCards ? CONTRACT_LIST_TABS : DEPRECATED_CONTRACT_LIST_TABS

  if (!permissions.hasContractAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          Contract repository
        </Text>
        <Button variant="primary" size="md" onClick={() => openDrawer(<ContractCreateDrawer />)}>
          + Create contract
        </Button>
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <ContractListTabs tabs={tabs} tab={queryState.primaryTab} />
        <VerticalDivider className="h-7" />
        <ContractListSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdown onOpen={() => track('contract_list_filters_dropdown_open')}>
          <AdvancedFiltersDropdownTrigger>
            <Button size="xs" variant="secondary" state="translucent" className="gap-1" isDisabled={!isInitialized}>
              <Icon icon="filter-funnel-01" />
              <span>Filters ({validAdvancedFilters?.length})</span>
            </Button>
          </AdvancedFiltersDropdownTrigger>
          <AdvancedFiltersDropdownContent title="Filter contracts" />
        </AdvancedFiltersDropdown>
      </div>
      <PageContent>
        <div className="flex flex-col gap-5">
          {isInitialized && validAdvancedFilters.length ? <AdvancedFiltersPillList /> : null}
          {enableContractListStatusCards && queryState.primaryTab !== 'archived' ? <ContractListStatusCards /> : null}
          <ContractList />
        </div>
      </PageContent>
    </Page>
  )
})

export const ContractListPage = () => {
  return (
    <ContractSearchFiltersContextProvider>
      <AdvancedFiltersProvider initialFilters={filterService.getFiltersFromUrl()}>
        <ContractListPageInternal />
      </AdvancedFiltersProvider>
    </ContractSearchFiltersContextProvider>
  )
}
