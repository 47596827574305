import { compact, filter, find, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { UserAvatarGroup } from '@cotiss/user'
import { TenderInvitationDrawer, TenderInvitationModel, useListTenderInvitation } from '@cotiss/tender-invitation'
import {
  PreferredSupplierAddDrawer,
  PreferredSupplierOrganisationModel,
  PreferredSupplierPopulatedModel,
  useListPreferredSupplier,
} from '@cotiss/preferred-supplier'
import {
  Badge,
  Icon,
  Input,
  NoDataPlaceholder,
  ScrollableTable,
  ScrollableTableColumn,
  TableRowCta,
  Text,
  Tooltip_DEPRECATED,
  datetimeService,
  paginationService,
  sortService,
  useCallout,
  useSortTable,
} from '@cotiss/common'

type Props = {
  tenderId: string
  isEditable?: boolean
}

type PreferredSupplierListSortKey = 'name' | 'date' | 'claimedStatus' | 'inviteStatus'

/**
 * Old preferred suppliers just have an organisation name until the preferred suppliers signs up,
 * then they will have a supplierOrganisation
 * New preferred suppliers will always have an supplierOrganisation as it's now created when the preferred supplier is created
 * If there is a supplierOrganisation we want to use the details from that instead of the organisationName
 * This type allows us to quickly get the correct details for the preferred supplier by just checking the type instead of
 * having to check both organisationName and supplierOrganisation every time
 */
type LabelledPreferredSupplier =
  | (Omit<PreferredSupplierPopulatedModel, 'organisationName'> & {
      type: 'pending'
      organisationName: string
      tenderInvitation?: TenderInvitationModel
    })
  | (Omit<PreferredSupplierPopulatedModel, 'supplierOrganisation'> & {
      type: 'notPending'
      supplierOrganisation: PreferredSupplierOrganisationModel
      tenderInvitation?: TenderInvitationModel
    })

export const TenderResponseContactsTab = memo(({ tenderId, isEditable }: Props) => {
  const [q, setQ] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const { sortKey, sortDirection, onSort } = useSortTable<PreferredSupplierListSortKey>({ initialKey: 'name' })
  const { preferredSuppliers, isLoading: isPreferredSuppliersLoading } = useListPreferredSupplier()
  const { tenderInvitations, isLoading: isTenderInvitationsLoading } = useListTenderInvitation({ tenderId })
  const isLoading = isPreferredSuppliersLoading || isTenderInvitationsLoading
  const { processedPreferredSuppliers, pagination } = useMemo(() => {
    const filteredSuppliers = filter(preferredSuppliers, (preferredSupplier) => {
      if (q) {
        const qLower = q.toLowerCase()

        if (!preferredSupplier.supplierOrganisation && preferredSupplier.organisationName?.toLowerCase().includes(qLower)) {
          return true
        }

        if (preferredSupplier.supplierOrganisation && preferredSupplier.supplierOrganisation.name?.toLowerCase().includes(qLower)) {
          return true
        }

        if (preferredSupplier.contacts?.some((contact) => contact.email.toLowerCase().includes(qLower))) {
          return true
        }

        if (preferredSupplier.supplierOrganisation?.account?.accountUser?.some((user) => user.email.toLowerCase().includes(qLower))) {
          return true
        }

        if (preferredSupplier.tags?.some((tag) => tag.toLowerCase().includes(qLower))) {
          return true
        }

        return false
      }

      return true
    })

    const labelledSuppliers: LabelledPreferredSupplier[] = filter<LabelledPreferredSupplier>(
      map(filteredSuppliers, (preferredSupplier) => {
        const tenderInvitation = find(tenderInvitations, (invite) => invite.preferredSupplier._id === preferredSupplier._id)

        if (preferredSupplier.supplierOrganisation) {
          return {
            ...preferredSupplier,
            tenderInvitation: tenderInvitation,
            supplierOrganisation: preferredSupplier.supplierOrganisation,
            type: 'notPending',
          }
        }

        return {
          ...preferredSupplier,
          tenderInvitation: tenderInvitation,
          organisationName: preferredSupplier.organisationName ?? '',
          type: 'pending',
        }
      }),
      // Filter out preferred suppliers that don't have a name or organisation name
      // This shouldn't happen but is a safety net as both fields are optional
      (preferredSupplier) => Boolean(preferredSupplier.supplierOrganisation || preferredSupplier.organisationName)
    )

    const result = labelledSuppliers.sort((a, b) => {
      if (sortKey === 'name') {
        const aOrgName = a.type === 'pending' ? a.organisationName : a.supplierOrganisation.name
        const bOrgName = b.type === 'pending' ? b.organisationName : b.supplierOrganisation.name

        return sortService.sortString(aOrgName, bOrgName)
      } else if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      } else if (sortKey === 'claimedStatus') {
        const aClaimedStatus = a.type === 'pending' ? '' : a.supplierOrganisation.claimedStatus
        const bClaimedStatus = b.type === 'pending' ? '' : b.supplierOrganisation.claimedStatus

        return sortService.sortString(aClaimedStatus, bClaimedStatus)
      } else if (sortKey === 'inviteStatus') {
        const aInviteStatus = a.tenderInvitation ? 'invited' : ''
        const bInviteStatus = b.tenderInvitation ? 'invited' : ''

        return sortService.sortString(aInviteStatus, bInviteStatus)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedPreferredSuppliers, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedPreferredSuppliers, pagination }
  }, [preferredSuppliers, q, tenderInvitations, sortKey, sortDirection, currentPage])

  const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
    if (!preferredSupplier.tags?.length) {
      return <Text size="sm">--</Text>
    }

    const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

    return (
      <>
        <Badge className="mr-1" state="outline" variant="secondary">
          {firstTag}
        </Badge>
        {Boolean(rest.length) && (
          <Tooltip_DEPRECATED tooltip={rest.join(', ')}>
            <Text className="font-medium" variant="link" size="sm">
              +{rest.length}
            </Text>
          </Tooltip_DEPRECATED>
        )}
      </>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: 'Name',
        onSort: () => onSort('name'),
        rows: map(processedPreferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text
              className="truncate"
              font="jakarta"
              title={preferredSupplier.type === 'pending' ? preferredSupplier.organisationName : preferredSupplier.supplierOrganisation.name}
            >
              {preferredSupplier.type === 'pending' ? preferredSupplier.organisationName : preferredSupplier.supplierOrganisation.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: 'Invite',
                onClick: () =>
                  openNarrowDrawer(
                    <TenderInvitationDrawer tenderId={tenderId} preferredSupplierId={preferredSupplier._id} isEditable={isEditable} />
                  ),
                isDisabled: !isEditable,
              }}
            />
          ),
        })),
      },
    ],
    [processedPreferredSuppliers, onSort, tenderId, isEditable]
  )

  const columns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: 'Organisation status',
        onSort: () => onSort('claimedStatus'),
        rows: map(processedPreferredSuppliers, (preferredSupplier) => ({
          content: () => {
            if (preferredSupplier.type === 'pending') {
              return <Text size="sm">--</Text>
            }

            return (
              <Badge
                state="outline"
                variant={preferredSupplier.supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                className="capitalize"
                title={
                  preferredSupplier.supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {preferredSupplier.supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {preferredSupplier.supplierOrganisation.claimedStatus.toLowerCase()}
              </Badge>
            )
          },
        })),
      },
      {
        heading: 'Invite status',
        onSort: () => onSort('inviteStatus'),
        rows: map(processedPreferredSuppliers, (preferredSupplier) => ({
          content: () => {
            if (!preferredSupplier.tenderInvitation) {
              return <Text size="sm">--</Text>
            }

            return (
              <Badge state="dot" variant="secondary">{`Invited (${
                preferredSupplier.tenderInvitation.contacts.length + (preferredSupplier.tenderInvitation.users?.length || 0)
              })`}</Badge>
            )
          },
        })),
      },
      {
        heading: 'Contacts',
        rows: map(processedPreferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(processedPreferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('date'),
        rows: map(processedPreferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ],
    [processedPreferredSuppliers, onSort]
  )

  if (!processedPreferredSuppliers.length && !isLoading && !q) {
    return (
      <NoDataPlaceholder
        label="You haven't added any contacts or have invitations pending"
        ctaLabel="+ Add contact"
        onCtaClick={() => openDrawer(<PreferredSupplierAddDrawer />)}
      />
    )
  }

  return (
    <div className="w-full">
      <div className="bg-white rounded-lg w-full p-4 mb-2">
        <div className="relative">
          <Icon className="absolute left-1 top-1/2 -translate-y-1/2" icon="search" variant="light" size={20} />
          <Input
            className="pl-8"
            value={q}
            onChange={({ target }) => setQ(target.value)}
            placeholder="Search contacts or emails"
            state="ghost"
            size="sm"
            isDisabled={isLoading}
          />
        </div>
      </div>

      <ScrollableTable isLoading={isLoading} fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} />
    </div>
  )
})
