import { findIndex } from 'lodash'
import React, { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import { useUserAccess } from '@cotiss/user'
import { ContractCreateDrawer, ContractList_DEPRECATED } from '@cotiss/contract'
import {
  Button,
  FourOhThreePage,
  Header,
  Page,
  PageContent,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  routerService,
  useAnalytics,
  useCallout,
  useTransition,
} from '@cotiss/common'

type ContractListTab_DEPRECATED = 'archived' | 'active'

const CONTRACT_LIST_TABS: TabModel<ContractListTab_DEPRECATED>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const ContractListPage_DEPRECATED = memo(() => {
  const { track } = useAnalytics()
  const { openDrawer } = useCallout()
  const { replace, push } = useHistory()
  const { permissions } = useUserAccess()
  const { tab } = useParams<{ tab?: ContractListTab_DEPRECATED }>()
  const { step, transition, onTransition } = useTransition({ initialStep: tab ? findIndex(CONTRACT_LIST_TABS, ({ id }) => id === tab) + 1 : 0 })

  useEffect(() => {
    track('contract_list_view')

    if (!tab) {
      replace(routerService.getHref('/contract/list/:tab?', { tab: 'active' }))
    }
  }, [])

  const handleTabChange = (_tab: ContractListTab_DEPRECATED) => {
    if (tab === 'active') {
      track('contract_list_active_view')
    }

    if (tab === 'archived') {
      track('contract_list_archived_view')
    }

    const newStep = findIndex(CONTRACT_LIST_TABS, ({ id }) => id === _tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    push(routerService.getHref('/contract/list/:tab?', { tab: _tab }))
  }

  if (!permissions.hasContractAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          Contract repository
        </Text>
        <Button variant="primary" size="sm" onClick={() => openDrawer(<ContractCreateDrawer />)}>
          + Create contract
        </Button>
      </Header>
      <PageContent>
        <Tabs<ContractListTab_DEPRECATED>
          className="border-b border-gray-300 w-full mb-8"
          tab={tab}
          tabs={CONTRACT_LIST_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <ContractList_DEPRECATED />}
            {step === 2 && <ContractList_DEPRECATED isArchived />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
