import React, { useReducer, createContext, useContext, useMemo } from 'react'
import { filterService, utilService } from '@cotiss/common'
import { Filter } from '@cotiss/common/models/filter.model'
import { CONTRACT_LIST_PRIMARY_TAB_FILTERS, CONTRACT_LIST_STATUS_CARD_FILTERS } from '@cotiss/contract/contract.constants'
import { useParams } from 'react-router-dom'

export type PrimaryTabKeys = 'active' | 'archived' | 'mine'
export const DEFAULT_PRIMARY_TAB: PrimaryTabKeys = 'active'

export type ContractSearchFiltersContextState = {
  searchQuery: string
  currentPage: number
  advancedFilters: Filter[]
  primaryTab: PrimaryTabKeys
  statusCard: string | null
  statusCardFilters: Filter[]
}

type UpdateSearchQueryAction = {
  type: 'UPDATE_SEARCH_QUERY'
  payload: string
}

type UpdateCurrentPageAction = {
  type: 'UPDATE_CURRENT_PAGE'
  payload: number
}

type UpdateAdvancedFiltersAction = {
  type: 'UPDATE_ADVANCED_FILTERS'
  payload: Filter[]
}

type UpdatePrimaryTabAction = {
  type: 'UPDATE_PRIMARY_TAB'
  payload: PrimaryTabKeys
}

type UpdateScoreCardAction = {
  type: 'UPDATE_STATUS_CARD'
  payload: { statusCard: string | null; statusCardFilters: Filter[] }
}

type ContractSearchFiltersContextAction =
  | UpdateSearchQueryAction
  | UpdateCurrentPageAction
  | UpdateAdvancedFiltersAction
  | UpdatePrimaryTabAction
  | UpdateScoreCardAction

const initialState: ContractSearchFiltersContextState = {
  searchQuery: '',
  currentPage: 1,
  advancedFilters: [],
  primaryTab: DEFAULT_PRIMARY_TAB,
  statusCard: null,
  statusCardFilters: [],
}

type ContractSearchFiltersContextValue = {
  processedFilters: Filter[]
  isQuerying: boolean
  queryState: ContractSearchFiltersContextState
  queryStateDispatch: React.Dispatch<ContractSearchFiltersContextAction>
}

const ContractSearchFiltersContext = createContext<ContractSearchFiltersContextValue | null>(null)

function processFilters({
  advancedFilters,
  primaryTab,
  statusCardFilters,
}: {
  advancedFilters: Filter[]
  primaryTab: PrimaryTabKeys
  statusCardFilters: Filter[]
}): Filter[] {
  return [...advancedFilters, ...statusCardFilters, ...(CONTRACT_LIST_PRIMARY_TAB_FILTERS[primaryTab]?.filters || [])]
}

const searchFiltersReducer = (
  state: ContractSearchFiltersContextState,
  action: ContractSearchFiltersContextAction
): ContractSearchFiltersContextState => {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        currentPage: 1,
        searchQuery: action.payload,
      }
    case 'UPDATE_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'UPDATE_ADVANCED_FILTERS':
      return {
        ...state,
        currentPage: 1,
        advancedFilters: action.payload,
      }
    case 'UPDATE_PRIMARY_TAB':
      return {
        ...state,
        currentPage: 1,
        primaryTab: action.payload,
        statusCard: null,
        statusCardFilters: [],
      }
    case 'UPDATE_STATUS_CARD':
      return {
        ...state,
        currentPage: 1,
        statusCard: action.payload.statusCard,
        statusCardFilters: action.payload.statusCardFilters,
      }
    default:
      return state
  }
}

export const useContractSearchFiltersContext = () => {
  const context = useContext(ContractSearchFiltersContext)
  if (!context) {
    throw new Error('useContractSearchFiltersContext must be used within a ContractSearchFiltersProvider')
  }
  return context
}

const initializeState = (initialState: ContractSearchFiltersContextState) => {
  const { statusCard, q: searchQuery } = utilService.getUrlSearchParams({ params: ['statusCard', 'q'] })
  const statusCardFilters = statusCard && CONTRACT_LIST_STATUS_CARD_FILTERS[statusCard]?.filters
  let initialStatusCardConfig = {}
  if (statusCardFilters) {
    initialStatusCardConfig = {
      statusCard,
      statusCardFilters,
    }
  }
  return {
    ...initialState,
    ...initialStatusCardConfig,
    searchQuery: searchQuery ?? '',
    advancedFilters: filterService.getFiltersFromUrl(),
  }
}

export const ContractSearchFiltersContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { tab } = useParams<{ tab?: PrimaryTabKeys }>()
  const [queryState, queryStateDispatch] = useReducer(
    searchFiltersReducer,
    { ...initialState, primaryTab: tab ?? DEFAULT_PRIMARY_TAB },
    initializeState
  )

  const value = useMemo(
    () => ({
      queryState,
      isQuerying: Boolean(queryState.searchQuery?.length || queryState.advancedFilters?.length > 0 || queryState.statusCard),
      queryStateDispatch,
      processedFilters: processFilters({
        advancedFilters: queryState.advancedFilters,
        primaryTab: queryState.primaryTab,
        statusCardFilters: queryState.statusCardFilters,
      }),
    }),
    [queryState]
  )

  return <ContractSearchFiltersContext.Provider value={value}>{children}</ContractSearchFiltersContext.Provider>
}
