import { map } from 'lodash'
import React, { memo, useMemo } from 'react'
import {
  Text,
  ScrollableTable,
  ScrollableTableColumn,
  Tooltip_DEPRECATED,
  Icon,
  OcdsCurrencyCode,
  utilService,
  Button,
  useCallout,
} from '@cotiss/common'
import {
  ContractMilestoneItemCta,
  ContractMilestoneModel,
  ContractWizardMilestoneModal,
  contractService,
  useGetContractShell,
} from '@cotiss/contract'

type Props = {
  milestones: ContractMilestoneModel[]
  isVariation: boolean
  contractShellId: string
  showErrors?: boolean
  currency?: OcdsCurrencyCode
  isEditable?: boolean
}

const CONTRACT_MILESTONES_TOTAL_ROW_ID = 'total-value-row'

export type ContractWizardMilestoneTableItem = {
  _id: string
  label: string
  length: number
  value: number
  variation: number
  exercised: number
  index: number
  description?: string
  referenceId?: string
  valueState?: 'error' | 'none'
  lengthState?: 'error' | 'none'
}

export const ContractWizardMilestoneTable = memo(({ milestones, isVariation, contractShellId, showErrors = false, currency, isEditable }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()

  // ----- Calculate the totals row values and error state of table --------
  const { milestonesWithTotals, approvedLength, approvedInitialValue } = useMemo(() => {
    if (!milestones.length) {
      return { milestonesWithTotals: [], approvedLength: 0, approvedInitialValue: 0 }
    }

    const parsedMilestones = contractService.parseContractMilestones(milestones)

    /* TODO:
      approvedMilestones will be the most recent published milestones, so when showing milestone history,
      it's not correct to use this to check for errors.
      Add a method which finds the last approved contract, relative to a given contract.
     */
    const approvedMilestones = contractShell?.contracts.length
      ? contractService.parseContractMilestones(contractService.getContract(contractShell, ['PUBLISHED'])?.milestones || [])
      : []

    const { length: approvedLength, value: approvedInitialValue } = contractService.getContractPriceDurationTotals(approvedMilestones)

    // This value is only used with variations.
    // Any updates to the milestone (total or length values) must still add up to these values
    const { differenceInTotalValues, differenceInMonths } = contractService.validateContractPriceDurationOrMilestoneVariation({
      approvedValues: approvedMilestones,
      newValues: parsedMilestones,
    })

    const {
      length: runningTotalLength,
      variation: runningTotalVariation,
      value: runningTotalValue,
      exercised: runningTotalExercised,
    } = contractService.getContractPriceDurationTotals(parsedMilestones)

    const milestonesWithTotals: ContractWizardMilestoneTableItem[] = [
      ...parsedMilestones,
      {
        _id: CONTRACT_MILESTONES_TOTAL_ROW_ID,
        index: milestones.length + 999, // Should always be the last row in the table
        label: 'Totals',
        variation: runningTotalVariation,
        exercised: runningTotalExercised,
        value: runningTotalValue,
        length: runningTotalLength,
        valueState: differenceInTotalValues && showErrors ? 'error' : 'none',
        lengthState: differenceInMonths && showErrors ? 'error' : 'none',
      },
    ]

    return {
      milestonesWithTotals,
      approvedLength,
      approvedInitialValue,
    }
  }, [milestones])
  // ----------------------------------------------------------------

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: ' ',
      rows: map(milestonesWithTotals, ({ _id, exercised, label }) => ({
        variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
        content: () => (
          <>
            {/* If it's the 'totals' row, or if this value hasn't yet been exercised */}
            {(_id === CONTRACT_MILESTONES_TOTAL_ROW_ID || (_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && !exercised)) && (
              <div className="flex">
                <Text className="font-semibold truncate" font="jakarta">
                  {label}
                </Text>
              </div>
            )}
            {/* If it's not the 'totals' row and the value has been exercised */}
            {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && Boolean(exercised) && (
              <Tooltip_DEPRECATED position="centre" tooltip="This period has been exercised">
                <Text className="font-semibold truncate" font="jakarta" variant="light">
                  {label}
                </Text>
              </Tooltip_DEPRECATED>
            )}
          </>
        ),
        ...(isEditable &&
          !exercised &&
          _id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && {
            cta: <ContractMilestoneItemCta milestoneId={_id} contractShellId={contractShellId} />,
          }),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Description',
      rows: map(milestonesWithTotals, ({ _id, description }) => ({
        variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
        content: () => (
          <Text title={description} className="whitespace-pre-wrap min-w-[200px] line-clamp-2">
            {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? '' : description || '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Length (months)',
      thClassName: 'whitespace-no-wrap',
      rows: map(milestonesWithTotals, ({ _id, length, lengthState, exercised }) => ({
        variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID && (
              <div className="flex items-center">
                {lengthState === 'error' && (
                  <Tooltip_DEPRECATED className="flex items-center" tooltip={`Initial approved length of this contract was ${approvedLength} months`}>
                    <Icon icon="alert-circle" className="mr-1" variant="danger" />
                    <Text variant="danger">{length}</Text>
                  </Tooltip_DEPRECATED>
                )}
                {lengthState !== 'error' && <Text variant="secondary">{length}</Text>}
              </div>
            )}
            {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{length}</Text>}
          </>
        ),
      })),
    },
    {
      heading: 'Initial value',
      rows: map(milestonesWithTotals, ({ _id, value, valueState, exercised }) => ({
        variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID && (
              <div className="flex items-center">
                {valueState === 'error' && (
                  <Tooltip_DEPRECATED
                    className="flex items-center"
                    tooltip={`Initial approved value of this contract was $${approvedInitialValue.toLocaleString()}`}
                  >
                    <Icon icon="alert-circle" className="mr-1" variant="danger" />
                    <Text variant="danger">
                      {currency && `${utilService.formatAsCurrency(value, currency)}`}
                      {!currency && value.toLocaleString()}
                    </Text>
                  </Tooltip_DEPRECATED>
                )}
                {valueState !== 'error' && (
                  <Text variant={_id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'secondary' : 'none'}>
                    {currency && `${utilService.formatAsCurrency(value, currency)}`}
                    {!currency && value.toLocaleString()}
                  </Text>
                )}
              </div>
            )}
            {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{value.toLocaleString()}</Text>}
          </>
        ),
      })),
    },
  ]

  if (isVariation) {
    columns.push({
      heading: 'Variation',
      rows: map(milestonesWithTotals, ({ _id, variation = 0, exercised }) => ({
        variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID && (
              <Text variant="secondary">
                {Boolean(currency) && `${utilService.formatAsCurrency(variation, currency)}`}
                {!currency && variation.toLocaleString()}
              </Text>
            )}
            {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{variation.toLocaleString()}</Text>}
          </>
        ),
      })),
    })
  }

  columns.push({
    heading: 'Total',
    rows: map(milestonesWithTotals, ({ _id, value, variation = 0, exercised }) => ({
      variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
      tdClassName: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'font-semibold' : '',
      content: () => (
        <>
          {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID && (
            <Text variant="secondary">
              {Boolean(currency) && `${utilService.formatAsCurrency(value + variation, currency)}`}
              {!currency && (value + variation).toLocaleString()}
            </Text>
          )}
          {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{(value + variation).toLocaleString()}</Text>}
        </>
      ),
    })),
  })
  columns.push({
    heading: 'Exercised',
    rows: map(milestonesWithTotals, ({ _id, exercised }) => ({
      variant: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'primary' : 'white',
      tdClassName: _id === CONTRACT_MILESTONES_TOTAL_ROW_ID ? 'font-semibold' : '',
      content: () => (
        <>
          {_id === CONTRACT_MILESTONES_TOTAL_ROW_ID && (
            <Text variant="secondary">
              {Boolean(currency) && `${utilService.formatAsCurrency(exercised, currency)}`}
              {!currency && exercised.toLocaleString()}
            </Text>
          )}
          {_id !== CONTRACT_MILESTONES_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{exercised.toLocaleString()}</Text>}
        </>
      ),
    })),
  })

  return (
    <ScrollableTable
      emptyCta={
        isEditable && (
          <Button
            size="sm"
            state="text"
            variant="secondary"
            onClick={() => openModal(<ContractWizardMilestoneModal contractShellId={contractShellId} />)}
            isDisabled={isLoading}
          >
            + Add milestone
          </Button>
        )
      }
      isLoading={isLoading}
      fixedColumnsWidth={180}
      fixedColumns={fixedColumns}
      columns={columns}
    />
  )
})
