import { Icon, IconType, Skeleton, Tooltip } from '@cotiss/common'
import classNames from 'classnames'

import React from 'react'

type Props = {
  label: string
  icon: IconType
  filteredSubtotal: number
  total: number
  isActive: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onClick: () => void
  shouldShowTotal: boolean
  iconFill?: string
  iconStroke?: string
  tooltip?: string
  className?: string
}

function numberOrDefault(value: number) {
  return isFinite(value) ? value : '—'
}

export const StatusCard = ({
  label,
  icon,
  filteredSubtotal,
  total,
  isActive,
  isLoading,
  isDisabled,
  onClick,
  shouldShowTotal,
  iconFill,
  iconStroke,
  tooltip,
  className,
}: Props) => {
  return (
    <button
      className={classNames(
        'flex flex-1 gap-3 p-3 min-w-[220px] h-16 flex-shrink-0 appearance-none hover:bg-gray-50 transition-colors duration-200',
        {
          'bg-secondary-50 border-secondary-300 ring-1 ring-inset ring-secondary-300': isActive,
        },
        className
      )}
      onClick={onClick}
      type="button"
      disabled={isDisabled || isLoading}
    >
      <div className={classNames('flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center', iconFill, iconStroke)}>
        <Icon icon={icon} size={18} />
      </div>
      <div className="flex-1 text-left overflow-hidden">
        <div className="text-xs text-gray-400 truncate max-w-full">{label}</div>
        <div className="text-secondary-900 font-medium truncate flex items-center gap-2 h-6">
          {isLoading ? (
            <Skeleton className="h-4 w-8" />
          ) : (
            <div>
              {numberOrDefault(filteredSubtotal)}
              {shouldShowTotal ? <span className="text-gray-400"> / {numberOrDefault(total)}</span> : null}
            </div>
          )}
          {tooltip ? (
            <Tooltip tooltip={tooltip}>
              <div className="inline-flex text-gray-400">
                <Icon icon="info-circle" size={14} />
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </button>
  )
}
