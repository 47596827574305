import { findIndex, includes } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useState } from 'react'
import { GqlCreatePerformanceScorecardMetricInput, GqlPerformanceScorecardMetricFrequency } from '@gql'
import {
  PerformanceScorecardMetricCreateConfigureStep,
  PerformanceScorecardMetricCreateMetricStep,
  usePerformanceScorecard,
  usePerformanceScorecardMetric,
} from '@cotiss/performance'
import {
  Button,
  datetimeService,
  Drawer,
  Icon,
  Month,
  MONTH_OPTIONS,
  MONTHS_WITH_30_DAYS,
  sentryService,
  Text,
  TransitionContainer,
  useCallout,
  useToast,
  useTransition,
  Weekday,
  WEEKDAY_OPTIONS,
} from '@cotiss/common'

export type PerformanceScorecardMetricCreateFormData = {
  performanceMetricId: string
  startDate: Date | null
  target: string
  frequency: GqlPerformanceScorecardMetricFrequency
  frequencyEndOnDay: string
  frequencyEndOnMonth: Month
  frequencyEndOnWeekday: Weekday
  frequencyMonthRepeat: string
}

type Props = {
  performanceScorecardId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardMetricCreateDrawer = memo(({ performanceScorecardId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const { queryPerformanceScorecardView } = usePerformanceScorecard()
  const { mutateCreatePerformanceScorecardMetric } = usePerformanceScorecardMetric()
  const [formData, setFormData] = useState<PerformanceScorecardMetricCreateFormData>({
    performanceMetricId: '',
    startDate: null,
    target: '',
    frequency: 'annually',
    frequencyEndOnDay: '31',
    frequencyEndOnMonth: 'December',
    frequencyEndOnWeekday: 'Monday',
    frequencyMonthRepeat: '',
  })

  const handleSubmit = async () => {
    if (step === 1) {
      return onTransition({ step: 2 })
    }

    // This should not be possible, just adding so typescript doesn't complain.
    if (!formData.startDate) {
      return
    }

    setIsSaving(true)

    try {
      const input: GqlCreatePerformanceScorecardMetricInput = {
        performanceMetricId: formData.performanceMetricId,
        performanceScorecardId,
        // Because we are storing the `startDate` as a `Date` datatype, we have to explicitly format the date as a date string. Otherwise we run the risk of storing a date that is a day off.
        startDate: datetimeService.format(formData.startDate, 'yyyy-MM-dd'),
        target: Number(formData.target),
        frequency: formData.frequency,
        frequencyEndOnDay: null,
        frequencyEndOnMonth: null,
        frequencyEndOnWeekday: null,
        frequencyMonthRepeat: null,
      }

      if (formData.frequency === 'annually') {
        input.frequencyEndOnMonth = findIndex(MONTH_OPTIONS, ({ value }) => value === formData.frequencyEndOnMonth)
        input.frequencyEndOnDay = Number(formData.frequencyEndOnDay)
      } else if (formData.frequency === 'monthly') {
        input.frequencyEndOnDay = Number(formData.frequencyEndOnDay)
      } else if (formData.frequency === 'weekly') {
        input.frequencyEndOnWeekday = findIndex(WEEKDAY_OPTIONS, ({ value }) => value === formData.frequencyEndOnWeekday) + 1
      }

      await mutateCreatePerformanceScorecardMetric(input)
      await Promise.all([queryPerformanceScorecardView({ performanceScorecardId }), onSubmit()])
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  useEffect(() => {
    const { frequency, frequencyEndOnMonth, frequencyEndOnDay } = formData

    if (!frequencyEndOnDay) {
      setFormData({ ...formData, frequencyEndOnDay: '1' })
    }

    if (frequency === 'annually') {
      if (includes(MONTHS_WITH_30_DAYS, frequencyEndOnMonth) && Number(frequencyEndOnDay) > 30) {
        setFormData({ ...formData, frequencyEndOnDay: '30' })
      } else if (frequencyEndOnMonth === 'February' && Number(frequencyEndOnDay) > 28) {
        setFormData({ ...formData, frequencyEndOnDay: '28' })
      }
    }
  }, [formData])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      Add metric
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence initial={false} mode="wait">
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isDisabled={!formData.performanceMetricId}>
              Continue <Icon className="ml-1" icon="arrow-right" />
            </Button>
            <Text className="ml-2">{formData.performanceMetricId ? '1' : '0'} selected</Text>
          </div>
        )}
        {step === 2 && (
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Save
          </Button>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <PerformanceScorecardMetricCreateMetricStep formData={formData} setFormData={setFormData} />}
          {step === 2 && (
            <PerformanceScorecardMetricCreateConfigureStep
              formData={formData}
              setFormData={setFormData}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
