import React, { memo } from 'react'
import { PopoverTrigger } from '@cotiss/common/components/popover.component'

type Props = {
  children: React.ReactNode
}

export const AdvancedFiltersDropdownTrigger = memo(({ children }: Props) => {
  return <PopoverTrigger asChild>{children}</PopoverTrigger>
})
