import React, { memo, useMemo, useState } from 'react'
import { filter, includes, map, some, startCase, toLower } from 'lodash'
import { useListUser, userService } from '@cotiss/user'
import { GqlPerformanceScorecardUserFieldsFragment } from '@gql'
import { usePerformanceScorecardUser } from '@cotiss/performance'
import {
  Badge,
  Button,
  Checkbox,
  Drawer,
  Icon,
  sentryService,
  Table,
  TableColumn,
  TableHeader,
  Text,
  Tooltip_DEPRECATED,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

type Props = {
  performanceScorecardId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardOwnerAddDrawer = memo(({ performanceScorecardId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [userIds, setUserIds] = useState<string[]>([])
  const { users, isLoading: isUsersLoading } = useListUser()
  const { mutateCreatePerformanceScorecardUsers, queryPerformanceScorecardUserList } = usePerformanceScorecardUser()
  const [performanceScorecardUsers, setPerformanceScorecardUsers] = useState<GqlPerformanceScorecardUserFieldsFragment[]>([])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { items: performanceScorecardUsers } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, role: 'owner' },
      })

      setPerformanceScorecardUsers(performanceScorecardUsers)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [])

  const handleSubmit = async () => {
    setIsSaving(true)

    try {
      await mutateCreatePerformanceScorecardUsers({
        performanceScorecardId,
        performanceScorecardUsers: map(userIds, (userId) => ({ userId, roles: ['owner'] })),
      })
      await onSubmit()
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleChange = (userId: string) => {
    if (includes(userIds, userId)) {
      setUserIds(filter(userIds, (userIdToCheck) => userIdToCheck !== userId))
    } else {
      setUserIds([...userIds, userId])
    }
  }

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(users, ({ _id, permissions }) => {
          const isAdded = some(performanceScorecardUsers, { userId: _id })

          return {
            content: () => (
              <Checkbox
                value={_id}
                onChange={() => handleChange(_id)}
                isChecked={isAdded || includes(userIds, _id)}
                isDisabled={isAdded || isSaving || !includes(permissions, 'PERFORMANCE_MANAGER')}
              />
            ),
          }
        }),
      },
      {
        heading: 'Name',
        rows: map(users, (user) => {
          const isAdded = some(performanceScorecardUsers, { userId: user._id })
          const hasPermission = includes(user.permissions, 'PERFORMANCE_MANAGER')

          return {
            content: () => <Text>{userService.getFullName(user)}</Text>,
            cta: (isAdded || !hasPermission) && (
              <Tooltip_DEPRECATED
                tooltipClassName="text-center z-1"
                tooltip={isAdded ? 'Already added' : 'User needs the "performance manager" permission to be assigned as an owner.'}
                width={isAdded ? 150 : 250}
              >
                <Icon icon="lock" />
              </Tooltip_DEPRECATED>
            ),
          }
        }),
      },
      {
        heading: 'Email',
        rows: map(users, ({ email }) => ({
          content: () => <Text>{email}</Text>,
        })),
      },
      {
        heading: 'Roles',
        rows: map(users, ({ permissions }) => ({
          content: () => (
            <div>
              {!permissions.length && '--'}
              {map(permissions, (permission) => (
                <Badge key={permission} className="mr-1" state="translucent" variant="secondary">
                  {startCase(toLower(permission))}
                </Badge>
              ))}
            </div>
          ),
        })),
      },
    ]

    return { columns }
  }, [userIds, users, performanceScorecardUsers])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      Add owner
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!userIds.length}>
        Save
      </Button>
      <Text className="ml-2">{userIds.length} selected</Text>
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <TableHeader>
        <Text className="font-medium" size="lg">
          Select user
        </Text>
        <Text variant="light">Select a user below to continue.</Text>
      </TableHeader>
      <Table columns={columns} isLoading={isLoading || isUsersLoading} />
    </Drawer>
  )
})
